.el-date-editor.el-input[data-v-5a600c46], .el-date-editor.el-input__inner[data-v-5a600c46] {
  width: 180px !important;
}
.save_box[data-v-5a600c46] {
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content[data-v-5a600c46] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content span[data-v-5a600c46] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-5a600c46] {
  padding: 0;
  margin: 0;
}
.content td[data-v-5a600c46] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.left_k[data-v-5a600c46] {
  text-indent: 2em;
}
.left_b[data-v-5a600c46] {
  text-indent: 4em;
}
.left_x[data-v-5a600c46] {
  text-indent: 5em;
}
.left_d[data-v-5a600c46] {
  text-indent: 8em;
}
.center[data-v-5a600c46] {
  text-align: center;
}
.left[data-v-5a600c46] {
  text-align: left;
}
.right[data-v-5a600c46] {
  text-align: right;
}
.weight[data-v-5a600c46] {
  font-weight: 700;
}
.row_box[data-v-5a600c46] {
  border-bottom: 1px solid #eee;
}
.big_box4[data-v-5a600c46] {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly[data-v-5a600c46] {
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}